/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.GeneralFooter___2Vzxx {
  background: #1d1b2e;
  padding: 64px;
}
@media (max-width: 575px) {
  .GeneralFooter___2Vzxx {
    padding: 24px;
  }
}
.GeneralFooter___2Vzxx .container {
  max-width: 1440px;
  margin: 0 auto;
}
.GeneralFooter___2Vzxx .link-group-name {
  color: #ffffff;
  font-family: 'PingFang SC';
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
}
.GeneralFooter___2Vzxx .footer-link {
  color: #ffffff;
  font-family: 'PingFang SC';
  font-size: 18px;
  line-height: 42px;
}
.GeneralFooter___2Vzxx .footer-link + .footer-link {
  margin-top: 16px;
}
