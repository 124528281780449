/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pos-web-site-home-page-enh.forMobile .playerTopCover .playerButton.pureButton {
  width: 50px !important;
  height: 50px !important;
}
.pos-web-site-home-page-enh .ant-layout-content {
  padding-top: 0px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.pos-web-site-home-page-enh .emphasis {
  color: #e00051;
}
.pos-web-site-home-page-enh .title1,
.pos-web-site-home-page-enh .title2 {
  font-family: 'Proxima Nova' !important;
  font-weight: 700  !important;
  font-size: 42px   !important;
  line-height: normal  !important;
  white-space: normal;
  color: #ffffff !important;
}
.pos-web-site-home-page-enh .subtitle {
  font-family: 'Proxima Nova';
  font-size: 24px;
  line-height: 29px;
  line-height: normal;
  white-space: normal;
  color: #ffffff;
  font-weight: 700  !important;
}
.pos-web-site-home-page-enh .secondary {
  font-family: 'Proxima Nova';
  font-size: 22px;
  line-height: normal;
  color: #92929F;
  font-style: normal;
  font-weight: 500 !important;
}
@media (max-width: 575px) {
  .pos-web-site-home-page-enh .secondary {
    font-size: 19px;
  }
}
.pos-web-site-home-page-enh .buttonText {
  font-family: 'Proxima Nova';
  font-size: 22px;
  line-height: 27px;
  color: #ffffff;
}
.pos-web-site-home-page-enh .ant-btn-lg {
  font-family: 'Proxima Nova';
  height: 60px;
  font-size: 22px;
  border-radius: 60px;
  line-height: 58px;
  font-weight: 600;
}
.topSection___1wPRM {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('https://pos-static.chowbus.com/assets/home_top_cover.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.topSection___1wPRM::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: block;
  z-index: 2;
}
.topSection___1wPRM .backgroundVideo___2M9KD {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.topSection___1wPRM .backgroundVideo___2M9KD video {
  width: 100%;
  height: 100%;
}
.topSectionMainContainer___CcuKm {
  max-width: 1440px;
  width: 100%;
  z-index: 3;
}
.topSectionMain___2W1Kp {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.topSectionMain___2W1Kp .ant-btn {
  color: #E00051;
}
.topSectionMain___2W1Kp .ant-btn-primary {
  color: #ffffff;
}
.topSectionMain___2W1Kp .title2 {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.playVideo32IconContainer___3AeY- {
  width: 32px;
  height: 32px;
  position: relative;
}
.forMobile___3URY7 .playVideo32IconContainer___3AeY- {
  width: 25px;
  height: 25px;
}
.forMobile___3URY7 .title1,
.forMobile___3URY7 .title2 {
  width: 100%;
  text-align: center;
  color: #1D1B2E !important;
  font-size: 24px !important;
  line-height: 29px !important;
}
.forMobile___3URY7 .subtitle {
  color: #1D1B2E !important;
  font-size: 14px !important;
  line-height: 30px !important;
}
.forMobile___3URY7 .ant-btn-lg {
  height: 40px;
  font-size: 14px;
  border-radius: 30px;
  line-height: 38px;
  font-weight: 600;
}
.forMobile___3URY7 .topSection___1wPRM {
  margin-top: 30px;
}
.forMobile___3URY7 .topSectionBottom___2nFhb {
  padding: 30px 0;
}
.forMobile___3URY7 .topSectionBottom___2nFhb .left___HJwcC .emphasisItem___2HPRG {
  font-size: 24px;
  line-height: 32px;
}
.forMobile___3URY7 .topSectionBottom___2nFhb .right___3N6nE {
  margin-top: 15px;
}
.forMobile___3URY7 .topSectionBottom___2nFhb .right___3N6nE .listItem___AVSz5 {
  font-size: 14px;
  line-height: 24px;
}
.forMobile___3URY7 .topSectionBottom___2nFhb .right___3N6nE .listItem___AVSz5::before {
  width: 24px;
  height: 24px;
}
.topSectionBottom___2nFhb {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 50px 0px;
  max-width: 1440px;
  margin: 0 auto;
}
.topSectionBottom___2nFhb .topSectionBottomContainer___1LLvk {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.topSectionBottom___2nFhb .topSectionBottomContainer___1LLvk .topSectionBottomLeft___AZsWA .whyloveChowbus___1HR-y {
  white-space: pre-line;
  line-height: 56px;
  font-size: 38px;
  padding: 0 10px;
}
.topSectionBottom___2nFhb .topSectionBottomContainer___1LLvk .topSectionBottomRight___AyqJO {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.topSectionBottom___2nFhb .topSectionBottomContainer___1LLvk .row___r9ytv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 991px) {
  .topSectionBottom___2nFhb .topSectionBottomContainer___1LLvk .row___r9ytv {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  .topSectionBottom___2nFhb .topSectionBottomContainer___1LLvk .row___r9ytv:last-child {
    margin-bottom: 0;
  }
}
.topSectionBottom___2nFhb .emphasisItem___2HPRG {
  color: #1D1B2E;
  font-family: 'Proxima Nova' !important;
  font-size: 42px;
  line-height: 56px;
  font-weight: 700;
  min-width: 400px;
}
@media (max-width: 991px) {
  .topSectionBottom___2nFhb .emphasisItem___2HPRG {
    min-width: 100%;
    text-align: center;
    font-size: 38px;
    line-height: 50px;
  }
}
@media (max-width: 575px) {
  .topSectionBottom___2nFhb .emphasisItem___2HPRG {
    font-size: 24px;
    line-height: 32px;
  }
}
.topSectionBottom___2nFhb .listItem___AVSz5 {
  color: #1D1B2E;
  font-family: 'Proxima Nova' !important;
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  margin: 10px 0;
  flex: 1;
}
@media (max-width: 991px) {
  .topSectionBottom___2nFhb .listItem___AVSz5 {
    margin: 5px auto;
    font-size: 20px;
    line-height: 20px;
  }
}
.topSectionBottom___2nFhb .listItem___AVSz5::before {
  content: '';
  width: 32px;
  height: 32px;
  display: flex;
  background-image: url('https://pos-static.chowbus.com/assets/list_item_icon_red.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}
@media (max-width: 991px) {
  .topSectionBottom___2nFhb .listItem___AVSz5::before {
    width: 28px;
    height: 28px;
  }
}
@media (max-width: 575px) {
  .topSectionBottom___2nFhb .listItem___AVSz5::before {
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 575px) {
  .topSectionBottom___2nFhb .listItem___AVSz5 {
    font-size: 14px;
    line-height: 24px;
  }
}
.forMobile___3URY7 .topSectionBottom___2nFhb .topSectionBottomContainer___1LLvk {
  flex-direction: column;
}
.forMobile___3URY7 .topSectionBottom___2nFhb .topSectionBottomContainer___1LLvk .topSectionBottomLeft___AZsWA .whyloveChowbus___1HR-y {
  white-space: normal;
  line-height: normal;
  font-size: 24px;
  padding: 0px 0px 10px 0;
}
.forMobile___3URY7 .topSectionBottom___2nFhb .topSectionBottomContainer___1LLvk .topSectionBottomRight___AyqJO {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mobile___2bsCu {
  font-size: 16px;
}
.topSectionWrapper___3cHlE.forSMDevice___37cLh .topSection___1wPRM .topSectionMain___2W1Kp .title2 {
  font-size: 38px !important;
}
.topSectionWrapper___3cHlE.forSMDevice___37cLh .topSection___1wPRM .topSectionMain___2W1Kp .subtitle {
  font-size: 20px;
}
.topSectionWrapper___3cHlE.forSMDevice___37cLh .topSectionBottom___2nFhb .left___HJwcC .emphasisItem___2HPRG {
  font-size: 38px;
}
.topSectionWrapper___3cHlE.forSMDevice___37cLh .topSectionBottom___2nFhb .right___3N6nE {
  margin-top: 10px;
}
.topSectionWrapper___3cHlE.forSMDevice___37cLh .topSectionBottom___2nFhb .right___3N6nE .listItem___AVSz5 {
  font-size: 20px;
}
/*******/
.everythingNeedInOnePOSSystemSection___20KNG {
  background-color: #F5F5F5;
  padding: 0 0 50px 0;
}
.everythingNeedInOnePOSSystemSection___20KNG .ant-carousel .slick-prev,
.everythingNeedInOnePOSSystemSection___20KNG .ant-carousel .slick-next,
.everythingNeedInOnePOSSystemSection___20KNG .ant-carousel .slick-prev:hover,
.everythingNeedInOnePOSSystemSection___20KNG .ant-carousel .slick-next:hover {
  font-size: inherit;
  color: currentColor;
  z-index: 10;
  font-size: 0px;
  width: 60px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: transparent;
  top: calc(50% - 100px);
}
.everythingNeedInOnePOSSystemSection___20KNG .ant-carousel .slick-prev {
  background-image: url('https://pos-static.chowbus.com/assets/left_arraow_icon.png');
  left: 60px;
  opacity: 0.5;
  transform: scale(0.9);
  transition: all;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.everythingNeedInOnePOSSystemSection___20KNG .ant-carousel .slick-next {
  right: 60px;
  background-image: url('https://pos-static.chowbus.com/assets/right_arraow_icon.png');
  opacity: 0.5;
  transform: scale(0.9);
  transition: all;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.everythingNeedInOnePOSSystemSection___20KNG .ant-carousel .slick-prev:hover,
.everythingNeedInOnePOSSystemSection___20KNG .ant-carousel .slick-next:hover {
  opacity: 1;
  transform: scale(1);
}
.everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU {
  width: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
}
.everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU .leftArrowIcon___C6alb {
  width: 60px;
  height: 60px;
  background-image: url('https://pos-static.chowbus.com/assets/left_arraow_icon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 80px;
  top: calc(50% - 100px);
  z-index: 10;
  cursor: pointer;
}
.everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU .rightArrowIcon___7zEG1 {
  width: 60px;
  height: 60px;
  background-image: url('https://pos-static.chowbus.com/assets/right_arraow_icon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  right: 80px;
  top: calc(50% - 100px);
  z-index: 10;
  position: absolute;
  cursor: pointer;
}
.everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU .productsGalleryWrapper___vKcNo {
  overflow-x: hidden;
  overflow-y: hidden;
}
.everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU .productsGalleryWrapper___vKcNo .productItemCardWrapper___nUN3T {
  padding: 0 10px !important;
}
.everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU .productsGalleryWrapper___vKcNo .productItemCardWrapper___nUN3T .productItemCard___3kxfy {
  min-height: 440px;
  border-radius: 16px;
  padding: 0 0 24px 0;
  overflow: hidden;
  background-color: #ffffff;
  cursor: pointer;
}
.everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU .productsGalleryWrapper___vKcNo .productItemCardWrapper___nUN3T .productItemCard___3kxfy .imageContainer___3ngOe {
  position: relative;
  height: 440px;
}
.everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU .productsGalleryWrapper___vKcNo .productItemCardWrapper___nUN3T .productItemCard___3kxfy h3.productItemTitle___28QIt,
.everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU .productsGalleryWrapper___vKcNo .productItemCardWrapper___nUN3T .productItemCard___3kxfy .productItemTitle___28QIt {
  display: block;
  color: #E00051;
  font-weight: 600;
  font-family: 'Proxima Nova' !important;
  font-size: 24px;
  padding: 16px 10px 0 10px;
  white-space: nowrap;
}
.everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU .productsGalleryWrapper___vKcNo .productItemCardWrapper___nUN3T .productItemCard___3kxfy .productDesc___euEab {
  width: 100%;
  color: #92929F;
  font-family: 'Proxima Nova' !important;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  padding: 5px 16px 0 16px;
  display: block;
}
.forMobile___3URY7 .everythingNeedInOnePOSSystemSection___20KNG {
  background-color: #ffffff;
  padding: 0 16px;
}
.forMobile___3URY7 .everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.forMobile___3URY7 .everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU .productItemCard___3kxfy {
  width: 100%;
  height: 50px;
  font-weight: 600;
  border-radius: 16px;
  background-color: #F5f5f5f5;
  color: #E00051;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 16px;
  margin-bottom: 16px;
}
.forMobile___3URY7 .everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU .productItemCard___3kxfy::after {
  content: '';
  width: 50px ;
  height: 50px;
  background-image: url('https://pos-static.chowbus.com/assets/more_icon.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.forMobile___3URY7 .everythingNeedInOnePOSSystemSection___20KNG .productsGallery___1PywU .productItemCard___3kxfy h3.productItemTitle___28QIt {
  color: #E00051;
  font-size: 16px;
}
.forSMDevice___37cLh .everythingNeedInOnePOSSystemSection___20KNG .ant-carousel .slick-prev,
.forSMDevice___37cLh .everythingNeedInOnePOSSystemSection___20KNG .ant-carousel .slick-next,
.forSMDevice___37cLh .everythingNeedInOnePOSSystemSection___20KNG .ant-carousel .slick-prev:hover,
.forSMDevice___37cLh .everythingNeedInOnePOSSystemSection___20KNG .ant-carousel .slick-next:hover {
  font-size: inherit;
  color: currentColor;
  z-index: 10;
  font-size: 0px;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: transparent;
  top: calc(50% - 100px);
}
.title_h2___3e4Hk {
  color: #1D1B2E;
  font-family: 'Proxima Nova' !important;
  font-size: 42px !important;
  line-height: 48px  !important;
  font-weight: 700;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 0 50px 0;
}
.everythingNeedInOnePOSSystemSection___20KNG .title_h2___3e4Hk.with_desc___1nIvQ {
  padding: 64px 0 10px 0;
}
.everythingNeedInOnePOSSystemSection___20KNG .commonTopDesc___3KRTc {
  padding-bottom: 40px !important;
  font-size: 26px !important;
}
.forMobile___3URY7 .title_h2___3e4Hk {
  font-size: 24px !important;
  line-height: 32px !important;
  font-weight: 700;
  text-align: center;
  padding: 30px 0 30px 0;
}
.forSMDevice___37cLh .title_h2___3e4Hk {
  font-size: 38px !important;
  line-height: 42px !important;
  font-weight: 700;
  text-align: center;
  padding: 30px 0 30px 0;
}
.ourCustomerBrandWall___3P6It {
  width: 100%;
}
.ourCustomerBrandWall___3P6It .scrollRowWrapper___34ukV {
  width: 100%;
  overflow: hidden;
}
.ourCustomerBrandWall___3P6It .scrollRowWrapper___34ukV .scrollRowContainer___a4pjx {
  width: max-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  animation-name: infiniteScroll___1f8lX;
  animation-duration: 60000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin-bottom: 10px;
}
.ourCustomerBrandWall___3P6It .scrollRowWrapper___34ukV .scrollRowContainer___a4pjx .columnItem___YNukX {
  width: 200px;
  padding: 0 5px;
  flex-shrink: 0;
}
@media (max-width: 991px) {
  .ourCustomerBrandWall___3P6It .scrollRowWrapper___34ukV .scrollRowContainer___a4pjx .columnItem___YNukX {
    width: 150px;
  }
}
@media (max-width: 575px) {
  .ourCustomerBrandWall___3P6It .scrollRowWrapper___34ukV .scrollRowContainer___a4pjx .columnItem___YNukX {
    width: 100px;
  }
}
.customerBrandContainer___1WnXJ {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonTopSectionWrapper___QGXyM,
.commonSectionWrapper___3PdqS {
  margin: 0 auto;
}
.commonTopSectionWrapper___QGXyM {
  padding-top: 50px;
}
.forMobile___3URY7 .ourCustomerBrandWallSection___34gW9 {
  padding: 0 16px;
}
.forSMDevice___37cLh .ourCustomerBrandWallSection___34gW9 {
  padding: 0 26px;
}
.commonSection___3i8GQ {
  padding: 0 0 64px 0;
}
.commonSection___3i8GQ.commonSectionNoHeader___LSNbo {
  padding: 0px 0 50px 0;
}
.whiteBackgroundcolor___3jkhK {
  background-color: #ffffff;
}
.lightGrayBackgroundcolor___3jTC_ {
  background-color: #F5F5F5;
}
.demoFormOnModalBackgroundcolor___2wMog {
  background-color: transparent;
  width: 100%;
  max-height: 100%;
}
.max1440Width___38cqJ {
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  margin: 0 auto;
}
.max1440WidthFull___20l0F {
  max-width: max(90vw, 1440px);
  margin: 0 auto;
}
.whyChooseChowbusGallery___-3Q3Q {
  width: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
}
.whyChooseChowbusGallery___-3Q3Q .slick-dots.slick-dots-bottom {
  bottom: -12px;
}
.whyChooseChowbusGallery___-3Q3Q .slick-dots .slick-active button {
  background-color: #ffffff !important;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 {
  overflow-x: hidden;
  overflow-y: hidden;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 .whyChooseChowbusItemCardWrapper___1NjV1 {
  width: 100%;
  padding: 0 10px !important;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 .whyChooseChowbusItemCardWrapper___1NjV1 .whyChooseChowbusItemCardOuter___31g2h {
  height: 380px;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 .whyChooseChowbusItemCardWrapper___1NjV1 .whyChooseChowbusItemCard___GCax_ {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 .whyChooseChowbusItemCardWrapper___1NjV1 .whyChooseChowbusItemCard___GCax_.no_link___gWnlQ {
  cursor: auto;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 .whyChooseChowbusItemCardWrapper___1NjV1 .whyChooseChowbusItemCard___GCax_ .leftParty___3-ctz {
  flex: 660;
  color: #ffffff;
  font-family: 'Proxima Nova' !important;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  padding: 16px 32px ;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #E00051;
  height: 100%;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 .whyChooseChowbusItemCardWrapper___1NjV1 .whyChooseChowbusItemCard___GCax_ .leftParty___3-ctz .restaurantInfo___2knBN {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 .whyChooseChowbusItemCardWrapper___1NjV1 .whyChooseChowbusItemCard___GCax_ .leftParty___3-ctz .restaurantInfo___2knBN .restaurantLogo___1Kvnv {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
  background-color: #ffffff;
  margin-right: 20px;
  overflow: hidden;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 .whyChooseChowbusItemCardWrapper___1NjV1 .whyChooseChowbusItemCard___GCax_ .leftParty___3-ctz .restaurantInfo___2knBN .restaurantLocation___2acTH {
  color: #ffffff;
  font-family: 'Proxima Nova' !important;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 .whyChooseChowbusItemCardWrapper___1NjV1 .whyChooseChowbusItemCard___GCax_ .rightParty___TV6oq {
  flex: 320;
  position: relative;
  height: 100%;
  background-color: #fafafe;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 .whyChooseChowbusItemCardWrapper___1NjV1 .whyChooseChowbusItemCard___GCax_ .desc___3p7Up {
  width: 100%;
  color: #ffffff;
  font-family: 'Proxima Nova' !important;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  display: block;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 .whyChooseChowbusItemCardWrapper___1NjV1 .whyChooseChowbusItemCard___GCax_ .seefullstoryLink___2uu-Z {
  margin-top: 15px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: #ffffff;
  display: block;
  border-radius: 20px;
  border: 1px solid #ffffff;
  padding: 4px 16px 4px 16px;
}
.whyChooseChowbusGallery___-3Q3Q .whyChooseChowbusGalleryyWrapper___22hg1 .whyChooseChowbusItemCardWrapper___1NjV1 .whyChooseChowbusItemCard___GCax_ .playButton___35dnC {
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 0px solid red;
  cursor: pointer;
  background-image: url('https://pos-static.chowbus.com/assets/video_play_button_white_bk.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: blue;
}
.forSMDevice___37cLh .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCardOuter___31g2h {
  height: 600px !important;
}
.forSMDevice___37cLh .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ {
  flex-direction: column-reverse;
}
.forSMDevice___37cLh .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .leftParty___3-ctz {
  flex: 200 !important;
}
.forSMDevice___37cLh .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .rightParty___TV6oq {
  flex: 333 !important;
  width: 100% !important;
  height: auto !important;
}
.forSMDevice___37cLh .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .desc___3p7Up {
  font-size: 20px !important;
  line-height: 30px !important;
}
.forSMDevice___37cLh .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .seefullstoryLink___2uu-Z {
  font-size: 18px !important;
  line-height: 28px !important;
}
.forSMDevice___37cLh .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .rightParty___TV6oq::after {
  width: 50px !important;
  height: 50px !important;
  left: calc(50% - 25px) !important;
  top: calc(50% - 25px) !important;
}
.forMobile___3URY7 .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCardOuter___31g2h {
  height: 600px !important;
}
.forMobile___3URY7 .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ {
  flex-direction: column-reverse;
}
.forMobile___3URY7 .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .leftParty___3-ctz {
  flex: 200 !important;
}
.forMobile___3URY7 .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .rightParty___TV6oq {
  flex: 333 !important;
  width: 100% !important;
  height: auto !important;
}
.forMobile___3URY7 .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .desc___3p7Up {
  font-size: 14px !important;
  line-height: 24px !important;
}
.forMobile___3URY7 .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .seefullstoryLink___2uu-Z {
  font-size: 14px !important;
  line-height: 24px !important;
}
.forMobile___3URY7 .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .playButton___35dnC {
  left: calc(50% - 20px) !important;
  top: calc(50% - 20px) !important;
  width: 40px  !important;
  height: 40px  !important;
}
.forMobile___3URY7 .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .restaurantLogo___1Kvnv {
  width: 50px !important;
  height: 50px !important;
}
.forMobile___3URY7 .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .restaurantLocation___2acTH {
  font-size: 12px !important;
  line-height: 22px !important;
}
.forMobile___3URY7 .whyChooseChowbusSection___mMf-7 .whyChooseChowbusItemCard___GCax_ .rightParty___TV6oq::after {
  width: 50px !important;
  height: 50px !important;
  left: calc(50% - 25px) !important;
  top: calc(50% - 25px) !important;
}
.forMobile___3URY7 .rightProductsForSpecialNeedsSection___KMRW9 {
  padding: 0 16px;
}
.forMobile___3URY7 .rightProductsForSpecialNeedsSection___KMRW9 .leftItemList___2ofB7 {
  margin-right: 0;
  justify-content: stretch;
}
.forMobile___3URY7 .rightProductsForSpecialNeedsSection___KMRW9 .leftItemList___2ofB7 .productItem___3wwLO {
  width: 100%;
  min-height: 75px;
  font-weight: 600;
  border-radius: 16px;
  background-color: #F5f5f5f5;
  color: #E00051;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 50px 16px 16px;
  margin-bottom: 16px;
  position: relative;
}
.forMobile___3URY7 .rightProductsForSpecialNeedsSection___KMRW9 .leftItemList___2ofB7 .productItem___3wwLO::after {
  position: absolute;
  right: 0;
  top: 0;
  content: '';
  width: 50px ;
  height: 50px;
  background-image: url('https://pos-static.chowbus.com/assets/more_icon.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.forMobile___3URY7 .rightProductsForSpecialNeedsSection___KMRW9 .leftItemList___2ofB7 .productItem___3wwLO .productTitle___3Ajma {
  color: #E00051;
  font-size: 18px !important;
  line-height: 22px !important;
}
.forMobile___3URY7 .rightProductsForSpecialNeedsSection___KMRW9 .leftItemList___2ofB7 .productItem___3wwLO .productDesc___euEab {
  margin-top: 10px;
  font-size: 14px !important;
  line-height: 17px !important;
}
.rightProductsForSpecialNeeds___2Ztzv {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.rightProductsForSpecialNeeds___2Ztzv .leftItemList___2ofB7 {
  margin-right: 80px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.rightProductsForSpecialNeeds___2Ztzv .leftItemList___2ofB7 .productItem___3wwLO {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
  cursor: pointer;
  padding: 16px;
}
.rightProductsForSpecialNeeds___2Ztzv .leftItemList___2ofB7 .productItem___3wwLO .productTitle___3Ajma {
  color: #1D1B2E;
  font-family: 'Proxima Nova' !important;
  font-size: 24px !important;
  line-height: 29px;
  font-weight: 600;
}
.rightProductsForSpecialNeeds___2Ztzv .leftItemList___2ofB7 .productItem___3wwLO .productDesc___euEab {
  margin-top: 10px;
  color: #92929F;
  font-family: 'Proxima Nova' !important;
  font-size: 16px !important;
  line-height: 26px;
  font-weight: 500;
}
.rightProductsForSpecialNeeds___2Ztzv .leftItemList___2ofB7 .productItem___3wwLO.active___249VS,
.rightProductsForSpecialNeeds___2Ztzv .leftItemList___2ofB7 .productItem___3wwLO:hover {
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}
.rightProductsForSpecialNeeds___2Ztzv .rightImageContainer___VGQtR {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}
.rightProductsForSpecialNeeds___2Ztzv .rightImageContainer___VGQtR .productItemImage___209pt {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  height: 100%;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
  border-radius: 16px;
  overflow: hidden;
}
.rightProductsForSpecialNeeds___2Ztzv .rightImageContainer___VGQtR .productItemImage___209pt.productItemImageActive___AWTVh {
  transform: translateX(0%);
  opacity: 1;
}
.scheduleYourFreeDemo___JwbNm {
  padding: 50px 60px !important;
}
.forMobile___3URY7 .scheduleYourFreeDemo___JwbNm {
  padding: 50px 16px !important;
}
.topScheduleYourFreeDemo___hg-ja {
  padding: 00px !important;
}
.forMobile___3URY7 .topScheduleYourFreeDemo___hg-ja {
  padding: 16px !important;
}
/**product*/
.commonTopSectionWrapper___QGXyM .commonSection___3i8GQ {
  padding: 0 0 40px 0;
}
.title_h1___mCulP {
  color: #1D1B2E;
  font-family: 'Proxima Nova' !important;
  font-size: 42px !important;
  line-height: 48px  !important;
  font-weight: 700;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonTopDesc___3KRTc {
  color: #1D1B2E;
  color: #92929f !important;
  font-family: 'Proxima Nova' !important;
  font-size: 22px !important;
  line-height: 32px !important;
  font-weight: 500;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0 20px 0 !important;
  white-space: pre-line;
  flex-direction: column;
}
.productVideoContainerWrapper___2gLV5 {
  position: relative;
  z-index: 2;
}
.productVideoContainerWrapper___2gLV5.forMobile___3URY7 {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.productVideoContainer___2eG7U {
  max-width: 900px;
  margin: 0 auto 40px auto;
}
.productAdvantagesSection___34IRF {
  background-color: #1D1B2E;
  position: relative;
  z-index: 1;
  padding: 118px 0 128px 0;
}
.productAdvantagesSection___34IRF .productAdvantageHeader___1TZdX {
  padding: 0 16px;
  margin: 0 auto;
}
.productAdvantagesSection___34IRF .productAdvantageHeader___1TZdX .title_h2___3e4Hk {
  color: #FFFFFF !important;
  font-family: 'Proxima Nova' !important;
  font-size: 32px !important;
  line-height: 52px !important;
  font-weight: 700 !important;
  text-align: center;
  padding: 0 32px;
  white-space: pre-line;
}
.productAdvantagesSection___34IRF .productAdvantageHeader___1TZdX .subdesc___1BAds {
  margin-top: 5px;
  color: #FFFFFF !important;
  font-family: 'Proxima Nova' !important;
  font-size: 18px !important;
  line-height: 32px !important;
  font-weight: 400 !important;
  text-align: center;
}
.productAdvantagesSection___34IRF .productAdvantagePoint___2ojr1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 60px;
}
.productAdvantagesSection___34IRF .productAdvantagePoint___2ojr1 .imgContainer___2xtmN {
  width: 120px;
  height: 120px;
  position: relative;
}
.productAdvantagesSection___34IRF .productAdvantagePoint___2ojr1 .title_h2___3e4Hk {
  color: #FFFFFF !important;
  font-family: 'Proxima Nova' !important;
  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 700 !important;
  text-align: center;
  padding: 20px 0 0 0 !important;
}
.productAdvantagesSection___34IRF .productAdvantagePoint___2ojr1 .subdesc___1BAds {
  color: #FFFFFF !important;
  font-family: 'Proxima Nova' !important;
  font-size: 14px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
  text-align: center;
  padding: 0 !important;
  white-space: pre-line;
}
.commonTopSectionWrapper___QGXyM.forMobile___3URY7 .title_h1___mCulP {
  font-size: 24px !important;
  line-height: 29px !important;
}
.commonTopSectionWrapper___QGXyM.forMobile___3URY7 .commonTopDesc___3KRTc {
  font-size: 14px !important;
  line-height: 24px !important;
}
.productAdvantagesSection___34IRF.forMobile___3URY7 {
  padding: 48px 0 48px 0;
}
.productAdvantagesSection___34IRF.forMobile___3URY7 .productAdvantageHeader___1TZdX .title_h2___3e4Hk {
  font-size: 24px !important;
  line-height: 29px !important;
  white-space: normal;
}
.productAdvantagesSection___34IRF.forMobile___3URY7 .productAdvantageHeader___1TZdX .subdesc___1BAds {
  font-size: 14px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
}
.productAdvantagesSection___34IRF.forMobile___3URY7 .productAdvantagePoint___2ojr1 .title_h2___3e4Hk {
  font-size: 18px !important;
  line-height: 24px !important;
}
.productAdvantagesSection___34IRF.forMobile___3URY7 .productAdvantagePoint___2ojr1 .subdesc___1BAds {
  font-size: 14px !important;
  line-height: 24px !important;
}
.productAdvantagesSection___34IRF.onlyVideo___3BZpj {
  margin-bottom: 80px;
}
.productAdvantagesSection___34IRF.forMobile___3URY7.onlyVideo___3BZpj {
  padding: 0;
  margin-bottom: 20px;
}
.commonProductFeatureWrapper___1x43z {
  padding: 96px 0;
}
.commonProductFeatureWrapper___1x43z .commonSection___3i8GQ {
  padding: 0 16px;
}
.commonProductFeatureWrapper___1x43z .title_h2___3e4Hk {
  color: #1D1B2E;
  font-family: 'Proxima Nova' !important;
  font-size: 32px !important;
  line-height: 52px !important;
  font-weight: 700;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  white-space: pre-line;
}
.commonProductFeatureWrapper___1x43z .commonProductFeatureDesc___3TwJC {
  font-family: 'Proxima Nova' !important;
  color: #1D1B2E !important;
  font-size: 18px !important;
  line-height: 32px !important;
  font-weight: 700;
  text-align: center;
}
.proudctFeaturiesWrapper___J_ijd {
  margin-top: 70px;
}
.proudctFeatureWrapper___xUq05 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
}
.proudctFeatureWrapper___xUq05 .getADemoButton___1GAG9 {
  margin-top: 18px;
}
.proudctFeatureWrapper___xUq05.proudctFeatureBorderCard___1AtB4 {
  border-radius: 16px;
  background-color: #ffffff;
  overflow: hidden;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb.reverse___hLMup {
  flex-flow: row-reverse;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb.reverse___hLMup .right___3N6nE {
  padding: 0 30px 0 0;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .left___HJwcC {
  flex: 528;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .right___3N6nE {
  padding: 0 30px 0 30px;
  flex: 604;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .right___3N6nE .proudctFeatureTitle___jfujp {
  white-space: pre-line;
  font-family: 'Proxima Nova' !important;
  color: #1D1B2E !important;
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .right___3N6nE .proudctFeatureDesc___4_1cx {
  white-space: pre-line;
  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 500;
  color: #92929F !important;
  font-family: 'Proxima Nova' !important;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .right___3N6nE .proudctFeaturePoints___2LAlc {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .right___3N6nE .proudctFeaturePoints___2LAlc .asSubFeature___3GJpy {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .right___3N6nE .proudctFeaturePoints___2LAlc .asSubFeature___3GJpy:last-child {
  margin-bottom: 0px;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .right___3N6nE .proudctFeaturePoints___2LAlc .asSubFeature___3GJpy .proudctFeatureTitle___jfujp {
  margin-bottom: 5px;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .right___3N6nE .proudctFeaturePoints___2LAlc .asSubFeature___3GJpy .proudctFeaturePoint___2kw0m::before {
  content: unset;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .right___3N6nE .proudctFeaturePoints___2LAlc .proudctFeaturePoint___2kw0m {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'Proxima Nova' !important;
  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 500;
  color: #92929F !important;
  margin-bottom: 15px;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .right___3N6nE .proudctFeaturePoints___2LAlc .proudctFeaturePoint___2kw0m:last-child {
  margin-bottom: 0px;
}
.proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .right___3N6nE .proudctFeaturePoints___2LAlc .proudctFeaturePoint___2kw0m::before {
  content: '';
  width: 22px;
  height: 22px;
  background-image: url('https://pos-static.chowbus.com/assets/product_feature_check_icon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
  flex-shrink: 0;
}
.proudctFeatureWrapper___xUq05.proudctFeatureBorderCard___1AtB4 .proudctFeature___2QqEb.reverse___hLMup .right___3N6nE {
  padding: 20px 20px 20px 20px;
}
.commonProductFeatureWrapper___1x43z.forMobile___3URY7 {
  padding: 46px 0;
}
.commonProductFeatureWrapper___1x43z.forMobile___3URY7 .title_h2___3e4Hk {
  font-size: 24px !important;
  line-height: 29px !important;
  white-space: normal;
}
.commonProductFeatureWrapper___1x43z.forMobile___3URY7 .commonProductFeatureDesc___3TwJC {
  font-size: 14px !important;
  line-height: 24px !important;
  color: #92929F !important;
  font-weight: 500  !important;
  margin-top: 10px;
}
.commonProductFeatureWrapper___1x43z.forMobile___3URY7 .proudctFeaturiesWrapper___J_ijd {
  margin-top: 30px;
}
.commonProductFeatureWrapper___1x43z.forMobile___3URY7 .proudctFeatureWrapper___xUq05 .getADemoButton___1GAG9 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}
.commonProductFeatureWrapper___1x43z.forMobile___3URY7 .proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb {
  flex-wrap: wrap;
}
.commonProductFeatureWrapper___1x43z.forMobile___3URY7 .proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .left___HJwcC {
  flex: 1;
  flex-shrink: 0;
  min-width: 100%;
  padding: 0px 0px 0 0px;
}
.commonProductFeatureWrapper___1x43z.forMobile___3URY7 .proudctFeatureWrapper___xUq05 .proudctFeature___2QqEb .right___3N6nE {
  flex: 1;
  flex-shrink: 0;
  min-width: 100%;
  padding: 30px 0px 0 0px;
}
.commonProductFeatureWrapper___1x43z.forMobile___3URY7 .proudctFeatureWrapper___xUq05.proudctFeatureBorderCard___1AtB4 .right___3N6nE {
  padding: 20px 20px 20px 20px;
}
/**service type*/
.whyChooseChowbusSection___mMf-7.forServiceType___3i8kf {
  padding-top: 0 !important;
}
.whyChooseChowbusSection___mMf-7.forServiceType___3i8kf .slick-dots.slick-dots-bottom {
  bottom: -12px;
}
.whyChooseChowbusSection___mMf-7.forServiceType___3i8kf .slick-dots button {
  background-color: #1D1B2E !important;
}
.whyChooseChowbusSection___mMf-7.forServiceType___3i8kf .slick-dots .slick-active button {
  background-color: #E00051 !important;
}
.whyChooseChowbusSection___mMf-7.lightGray___2OnvK {
  background-color: #ffffff;
}
.whyChooseChowbusSection___mMf-7.lightGray___2OnvK .leftParty___3-ctz {
  background-color: #F5F5F5 !important;
}
.whyChooseChowbusSection___mMf-7.lightGray___2OnvK .leftParty___3-ctz .desc___3p7Up {
  color: #1D1B2E !important;
}
.whyChooseChowbusSection___mMf-7.lightGray___2OnvK .leftParty___3-ctz .seefullstoryLink___2uu-Z {
  color: #1D1B2E !important;
  border-radius: 20px;
  border: 1px solid #1D1B2E !important;
  padding: 4px 16px 4px 16px;
}
.whyChooseChowbusSection___mMf-7.lightGray___2OnvK .leftParty___3-ctz .restaurantInfo___2knBN .restaurantLocation___2acTH {
  color: #1D1B2E !important;
}
/**usingServiceAdvantageSection**/
.usingServiceAdvantageSection___b7VN9 {
  padding: 72px 10px 0 10px;
}
.usingServiceAdvantageSection___b7VN9 .title_h2___3e4Hk {
  font-size: 28px !important;
  line-height: 36px !important;
  font-weight: 700;
  text-align: center;
  padding: 0 ;
}
.usingServiceAdvantageSection___b7VN9 .subdesc___1BAds {
  margin-top: 5px;
  color: #1D1B2E !important;
  font-family: 'Proxima Nova' !important;
  font-size: 18px !important;
  line-height: 32px !important;
  font-weight: 700 !important;
  text-align: center;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 75px;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .leftItemList___2ofB7 {
  margin-right: 80px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .leftItemList___2ofB7 .serviceAdvantagePoint___1zJWW {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
  cursor: pointer;
  padding: 18px;
  margin-bottom: 20px;
  background-color: #FFFFFF;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .leftItemList___2ofB7 .serviceAdvantagePoint___1zJWW:last-child {
  margin-bottom: 0;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .leftItemList___2ofB7 .serviceAdvantagePoint___1zJWW .advantagePointSubTitle___1ANrO {
  color: #1D1B2E;
  font-family: 'Proxima Nova' !important;
  font-size: 18px !important;
  line-height: 25px  !important;
  font-weight: 600;
  margin-top: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .leftItemList___2ofB7 .serviceAdvantagePoint___1zJWW .advantagePointTitle___1jAYk {
  color: #1D1B2E;
  font-family: 'Proxima Nova' !important;
  font-size: 24px !important;
  line-height: 29px  !important;
  font-weight: 700;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .leftItemList___2ofB7 .serviceAdvantagePoint___1zJWW .advantageItemList___3jslK {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 10px;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .leftItemList___2ofB7 .serviceAdvantagePoint___1zJWW .advantagePointDesc___3m9-2 {
  margin-top: 10px;
  color: #92929F;
  font-family: 'Proxima Nova' !important;
  font-size: 18px !important;
  line-height: 24px  !important;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .leftItemList___2ofB7 .serviceAdvantagePoint___1zJWW .advantagePointDesc___3m9-2::before {
  content: '';
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('https://pos-static.chowbus.com/assets/product_feature_check_icon.png');
  flex-shrink: 0;
  margin-right: 10px;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .leftItemList___2ofB7 .serviceAdvantagePoint___1zJWW.active___249VS,
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .leftItemList___2ofB7 .serviceAdvantagePoint___1zJWW:hover {
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .rightImageContainer___VGQtR {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .rightImageContainer___VGQtR .advantagePointImage___9Vw1n {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  height: 100%;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
  border-radius: 16px;
  overflow: hidden;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .rightImageContainer___VGQtR .advantagePointImage___9Vw1n img {
  border-radius: 16px !important;
  width: unset  !important;
  height: unset  !important;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .rightImageContainer___VGQtR .advantagePointImage___9Vw1n.advantagePointImageActive___6bah4 {
  transform: translateX(0%);
  opacity: 1;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .rightImageContainer___VGQtR .advantagePointMultipsImage___3hacu {
  flex-direction: column;
  width: 100%;
  opacity: 1;
  display: none;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
  border-radius: 16px;
  overflow: hidden;
}
.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .rightImageContainer___VGQtR .advantagePointMultipsImage___3hacu.advantagePointImageActive___6bah4 {
  transform: translateX(0%);
  display: flex;
}
.forSMDevice___37cLh.usingServiceAdvantageSection___b7VN9,
.forMobile___3URY7.usingServiceAdvantageSection___b7VN9 {
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: #F5F5F5;
}
.forSMDevice___37cLh.usingServiceAdvantageSection___b7VN9 .commonSection___3i8GQ,
.forMobile___3URY7.usingServiceAdvantageSection___b7VN9 .commonSection___3i8GQ {
  padding-bottom: 0 !important;
}
.forSMDevice___37cLh.usingServiceAdvantageSection___b7VN9 .title_h2___3e4Hk,
.forMobile___3URY7.usingServiceAdvantageSection___b7VN9 .title_h2___3e4Hk {
  font-size: 24px !important;
  line-height: 29px !important;
}
.forSMDevice___37cLh.usingServiceAdvantageSection___b7VN9 .subdesc___1BAds,
.forMobile___3URY7.usingServiceAdvantageSection___b7VN9 .subdesc___1BAds {
  font-size: 14px !important;
  line-height: 24px !important;
}
.forSMDevice___37cLh.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN,
.forMobile___3URY7.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN {
  margin-top: 30px;
}
.forSMDevice___37cLh.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .leftItemList___2ofB7,
.forMobile___3URY7.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .leftItemList___2ofB7 {
  margin-right: 0 !important ;
}
.forSMDevice___37cLh.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .serviceAdvantagePoint___1zJWW,
.forMobile___3URY7.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .serviceAdvantagePoint___1zJWW {
  padding: 0 !important;
  border-radius: 16px;
  overflow: hidden;
}
.forSMDevice___37cLh.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .serviceAdvantagePoint___1zJWW .advantagePointTop___11RiP,
.forMobile___3URY7.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .serviceAdvantagePoint___1zJWW .advantagePointTop___11RiP {
  width: 100%;
}
.forSMDevice___37cLh.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .serviceAdvantagePoint___1zJWW .advantagePointBottom___Fd_Bf,
.forMobile___3URY7.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .serviceAdvantagePoint___1zJWW .advantagePointBottom___Fd_Bf {
  padding: 16px;
}
.forSMDevice___37cLh.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .serviceAdvantagePoint___1zJWW .advantagePointSubTitle___1ANrO,
.forMobile___3URY7.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .serviceAdvantagePoint___1zJWW .advantagePointSubTitle___1ANrO {
  font-size: 16px !important;
  line-height: 22px  !important;
}
.forSMDevice___37cLh.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .serviceAdvantagePoint___1zJWW .advantagePointTitle___1jAYk,
.forMobile___3URY7.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .serviceAdvantagePoint___1zJWW .advantagePointTitle___1jAYk {
  font-size: 22px !important;
  line-height: 28px !important;
}
.forSMDevice___37cLh.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .serviceAdvantagePoint___1zJWW .advantagePointDesc___3m9-2,
.forMobile___3URY7.usingServiceAdvantageSection___b7VN9 .usingServiceAdvantage___3pQzN .serviceAdvantagePoint___1zJWW .advantagePointDesc___3m9-2 {
  font-size: 18px !important;
  line-height: 24px !important;
}
.frequentlyAskedQuestionsSection___2L1Oa {
  padding-top: 96px;
  padding-bottom: 96px;
}
.frequentlyAskedQuestionsSection___2L1Oa .title_h2___3e4Hk {
  padding-top: 0;
}
.frequentlyAskedQuestionsSection___2L1Oa .commonSection___3i8GQ {
  padding-bottom: 0;
}
.frequentlyAskedQuestionsSection___2L1Oa .frequentlyAskedQuestions___1wOA- {
  width: 100%;
}
.frequentlyAskedQuestionsSection___2L1Oa .frequentlyAskedQuestionsItemList___33cNb {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.frequentlyAskedQuestionsSection___2L1Oa .frequentlyAskedQuestionsItemList___33cNb .frequentlyAskedQuestionsItem___2LNXC {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  padding: 12px 24px;
  background-color: #F5F5F5;
  margin-bottom: 24px;
}
.frequentlyAskedQuestionsSection___2L1Oa .frequentlyAskedQuestionsItemList___33cNb .frequentlyAskedQuestionsItem___2LNXC:last-child {
  margin-bottom: 0;
}
.frequentlyAskedQuestionsSection___2L1Oa .frequentlyAskedQuestionsItemList___33cNb .frequentlyAskedQuestionsItem___2LNXC .frequentlyAskedQuestion___qLE_e {
  width: 100%;
  color: #1D1B2E;
  font-family: 'Proxima Nova' !important;
  font-size: 24px !important;
  line-height: 48px  !important;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.frequentlyAskedQuestionsSection___2L1Oa .frequentlyAskedQuestionsItemList___33cNb .frequentlyAskedQuestionsItem___2LNXC .frequentlyAskedQuestion___qLE_e::after {
  content: '';
  width: 60px;
  height: 60px;
  background-image: url('https://pos-static.chowbus.com/assets/arrow_up@60.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  transform: rotate(180deg);
  flex-shrink: 0;
}
.frequentlyAskedQuestionsSection___2L1Oa .frequentlyAskedQuestionsItemList___33cNb .frequentlyAskedQuestionsItem___2LNXC .frequentlyAskedQuestionAnswer___2MYlc {
  color: #1D1B2E;
  font-family: 'Proxima Nova' !important;
  font-size: 18px ;
  line-height: 24px;
  font-weight: 400;
  display: none;
  padding-bottom: 10px;
  cursor: auto;
  white-space: pre-line;
}
.frequentlyAskedQuestionsSection___2L1Oa .frequentlyAskedQuestionsItem___2LNXC.expand___3ybfP .frequentlyAskedQuestionAnswer___2MYlc {
  display: block;
}
.frequentlyAskedQuestionsSection___2L1Oa .frequentlyAskedQuestionsItem___2LNXC.expand___3ybfP .frequentlyAskedQuestion___qLE_e::after {
  transform: rotate(0deg);
}
.forMobile___3URY7.frequentlyAskedQuestionsSection___2L1Oa {
  padding-top: 24px;
  padding-bottom: 24px;
}
.forMobile___3URY7.frequentlyAskedQuestionsSection___2L1Oa .title_h2___3e4Hk {
  font-size: 24px !important;
  line-height: 32px !important;
}
.forMobile___3URY7.frequentlyAskedQuestionsSection___2L1Oa .commonSection___3i8GQ {
  padding-left: 16px;
  padding-right: 16px;
}
.forMobile___3URY7.frequentlyAskedQuestionsSection___2L1Oa .frequentlyAskedQuestionsItemList___33cNb .frequentlyAskedQuestionsItem___2LNXC .frequentlyAskedQuestion___qLE_e {
  font-size: 16px !important;
  line-height: 24px !important;
  align-items: center;
}
.forMobile___3URY7.frequentlyAskedQuestionsSection___2L1Oa .frequentlyAskedQuestion___qLE_e::after {
  width: 40px !important;
  height: 40px !important;
  flex-shrink: 0;
}
.forMobile___3URY7.frequentlyAskedQuestionsSection___2L1Oa .frequentlyAskedQuestionAnswer___2MYlc {
  margin-top: 10px;
  font-size: 16px !important;
  line-height: 24px !important;
}
/**production for loyalty**/
.productTopIntroductionForLoyaltyModeWrapper___32aSa {
  max-width: 1440px;
  padding: 0 16px;
  padding-top: 50px;
  margin: 0 auto;
  position: relative;
  background-color: transparent;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 530px;
}
.productTopIntroductionForLoyaltyModeWrapper___32aSa .commonTopSectionWrapper___QGXyM {
  padding-top: 30px !important;
  flex: 644  !important;
  margin-right: 20px;
}
.productTopIntroductionForLoyaltyModeWrapper___32aSa .productLoyaltyVideoContainerWrapper___EAf5z {
  max-width: 900px;
  flex: 664;
}
.productTopIntroductionForLoyaltyModeWrapper___32aSa.forMobile___3URY7 {
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.productTopIntroductionForLoyaltyModeWrapper___32aSa.forMobile___3URY7 .commonTopSectionWrapper___QGXyM {
  width: 100%;
  margin-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}
.productTopIntroductionForLoyaltyModeWrapper___32aSa.forMobile___3URY7 .commonTopSectionWrapper___QGXyM .commonSection___3i8GQ {
  padding-bottom: 0px;
}
.productTopIntroductionForLoyaltyModeWrapper___32aSa.forMobile___3URY7 .productLoyaltyVideoContainerWrapper___EAf5z {
  margin-left: 0 !important;
}
.productTopIntroductionForLoyaltyModeWrapper___32aSa.forMobile___3URY7 .topScheduleYourFreeDemo___hg-ja {
  padding: 0 !important;
}
.forMDDevice___V6GZT.productTopIntroductionForLoyaltyModeWrapper___32aSa {
  padding-left: 26px;
  padding-right: 26px;
}
@keyframes infiniteScroll___1f8lX {
  to {
    transform: translateX(-50%);
  }
}
